import React from 'react'
import { useNavigate,useParams,useLocation } from 'react-router-dom';

  
export const withNavigate = (Component) => {
    const Wrapper = (props) => {
      const navigate = useNavigate();
      const location = useLocation()
      let { paramku } = useParams(); 

      return (
        <Component
          navigate={navigate}
          paramku18={location}
          {...props}
          />
      );
    };
    
    return Wrapper;
  };