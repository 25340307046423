import React from "react";

//const Dashboard = React.lazy(() => import("./components/Dashboard"));
const Dashboard = React.lazy(() => import("./aces/Dashboard1"));
const Monitoring1 = React.lazy(() => import("./aces/Monitoring1"));
const Login2= React.lazy(() => import("./components2/Login2"));
const MyProfile = React.lazy(() => import("./master/MyProfile"));
const Sinkronize = React.lazy(() => import("./aces/Sinkronize"));
const laporanPenyimpangan1 = React.lazy(() => import("./aces/laporanSimpang1"));
//const laporanPenyimpangan1 = React.lazy(() => import("./aces/laporanPenyimpangan1"));
//master-reminder-group
const routes = [     
    { path: "/dashboard", name: "Dashboard", component: Dashboard },
    { path: "/wo-monitoring", name: "Monitoring1", component: Monitoring1 },
    { path: "/login2", name: "Login2", component: Login2 },
    { path: "/my-profile", name: "MyProfile", component: MyProfile },
    { path: "/sinkronize", name: "Sinkronize", component: Sinkronize },
    { path: "/laporan-penyimpangan1", name: "laporanPenyimpangan1", component: laporanPenyimpangan1 },
];

export default routes;
